import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import StackMediaStories from "../brands/StackMediaStories";
import StackMediaBrands from "../brands/StackAdsBrands";
import HeroSectionExtraHeading from "../HeroSectionExtraHeading";
import CTALink from "../styles/CTALink";
import HeroSection from "../HeroSection";
import HeroMetricsThree from "../HeroMetricsThree";
import { brandColor2, white } from "../styles/Colors";
import HeroTestimonial from "../HeroTestimonial";
import { Headline32 } from "../styles/Headings";
import OurCoreAudiences from "../brands/OurCoreAudiences";
import HeroBlueCTA from "../HeroBlueCTA";
import { FadeInRightSmall } from "../styles/animations";
import SponsorContentAnimation from "../SponsorContentAnimation";
import StackEmailAnimation from "../StackEmailAnimation";

// Images
import sponsoredDesktop from "../../images/33-sponsored-content-desktop.png";
import sponsoredMobile from "../../images/33-sponsored-content-mobile.png";
import stackBarDesktop from "../../images/30-stackbar-desktop.png";
import stackBarMobile from "../../images/30-stackbar-mobile.png";
import nativeAdDesktop from "../../images/31-native-ads-desktop.png";
import nativeAdMobile from "../../images/31-native-ads-mobile.png";

const sponsoredContentImages = {
  desktop: {
    src: sponsoredDesktop,
    width: 1299,
    height: 1628
  },
  mobile: {
    src: sponsoredMobile,
    width: 375,
    height: 404
  }
};

const stackBarImages = {
  desktop: {
    src: stackBarDesktop,
    width: 1498,
    height: 880
  },
  mobile: {
    src: stackBarMobile,
    width: 375,
    height: 503
  }
};

const nativeAdsImages = {
  desktop: {
    src: nativeAdDesktop,
    width: 1499,
    height: 881
  },
  mobile: {
    src: nativeAdMobile,
    width: 375,
    height: 504
  }
};

const BrandsStackMediaDefer = () => {
  const images = useStaticQuery(graphql`
    query {
      logoTestimonial: file(
        relativePath: { eq: "66-testimonial-vincero.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopTestimonial: file(
        relativePath: { eq: "67-testimonial-brandads-desktop.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mobileTestimonial: file(
        relativePath: { eq: "67-testimonial-brandads-media.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <>
      <StackMediaStories />

      <StackMediaBrands />

      <HeroSectionExtraHeading
        heading="YOUR STACKMEDIA TOOLKIT"
        top="01"
        title="SPONSORED CONTENT"
        images={sponsoredContentImages}
        AnimationComponent={SponsorContentAnimation}
      >
        Get discovered through unique sponsored content written by Stack’s{" "}
        <CTALink
          attrs={{ to: "/publishers/content-studio/" }}
          variant="white"
          bold
          underlined
        >
          Content Studio
        </CTALink>{" "}
        and published on top media sites. We quickly scale your content strategy
        for you, no additional contracts required.
      </HeroSectionExtraHeading>

      <HeroSection
        title="EMAIL NEWSLETTERS"
        top="02"
        displayComponent={<StackEmailAnimation forceActive={true} />}
        theme={{
          marginDesktop: "0 0 60px",
          paddingDesktop: "200px 0"
        }}
        reverse={true}
      >
        Land in the inboxes of hard-to-reach customers with inclusions in emails
        sent to millions of subscribers.
      </HeroSection>

      <HeroSection
        title="NATIVE AD UNITS"
        top="03"
        images={nativeAdsImages}
        alt={true}
        animation={FadeInRightSmall}
        theme={{
          imageTop: "64px",
          paddingDesktop: "200px 0",
          overflowWidth: "1000"
        }}
      >
        Unlike a traditional ad, our exclusive native ad units are integrated
        organically into the reader experience on our top partner sites — so
        you’ll never lose out to ad blockers.
      </HeroSection>

      <HeroSection title="STACKBAR" top="04" images={stackBarImages}>
        Feature your brand with a premium placement and strong call-to-action at
        the top of highly-trafficked publishers throughout our network.
      </HeroSection>

      <HeroMetricsThree
        noBackground={true}
        backgroundColor={brandColor2}
        content={[
          {
            number: "180",
            suffix: "M+",
            description: "Total Reach"
          },
          {
            number: "26",
            suffix: "%",
            description: "Avg CTR from Content <br/> to Product"
          },
          {
            number: "700",
            suffix: "+",
            description: "Publishers in Our Network"
          }
        ]}
      />

      <HeroTestimonial media={images} author="Vincero Watches">
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          The biggest hurdle of PR is simply getting the attention of the
          publication’s decision maker. Stack solves that problem through their
          existing relationships with hundreds of publishers.
        </Headline32>
        <Headline32 theme={{ margins: "18px 0", color: white }}>
          Stack’s team handled everything from excellent writing, CPC
          negotiations with publishers, and placement, all in much less time
          than it would have taken us from scratch.
        </Headline32>
      </HeroTestimonial>

      <OurCoreAudiences />

      <HeroBlueCTA
        title="GET REAL RESULTS"
        outline="WITH STACK"
        description="See how StackMedia can help you reach your target audiences on the sites they visit daily."
        linkLabel="Get Started"
        link={{ to: "/contact/?smopt=brand", state: { from: "brand" }, as: Link }}
      />
    </>
  );
};

export default BrandsStackMediaDefer;
