import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { Eyebrows16 } from "./styles/Eyebrows";
import { Headline32 } from "./styles/Headings";
import { black1, gray2, white } from "./styles/Colors";
import { Body20 } from "./styles/BodyTypes";
import IconDownload from "./icons/IconDownload";
import { inject, observer } from "mobx-react";
import { mediaMedium } from "./styles/variables";

const Div = styled(BackgroundImage)`
  width: 100%;
  height: 580px;
  max-width: 348px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background-color: ${black1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 34px;

  @media (${mediaMedium}) {
    padding: 32px 22px;
  }
`;

const Top = styled.div`
  position: relative;
  z-index: 2;
`;

const Bottom = styled.div`
  position: relative;
  z-index: 2;
`;

const Link = styled.a`
  display: inline-flex;
  align-items: center;
  margin: 0;
  text-decoration: none;
  color: ${white};
  transition: color 0.2s;

  svg {
    margin-left: 16px;
  }

  &:hover {
    cursor: pointer;
    color: ${gray2};
  }
`;

const StorySnippet = ({ title, image, topic, link }) => {
  return (
    <Div fluid={image}>
      <Top>
        <Eyebrows16 theme={{ color: white }}>{topic}</Eyebrows16>
      </Top>
      <Bottom>
        <Headline32 as="h4" theme={{ margins: "0 0 22px", color: white }}>
          {title}
        </Headline32>
        <Body20 as="span" theme={{ margins: "0" }}>
          <Link download href={link}>
            Get the Story
            <IconDownload />
          </Link>
        </Body20>
      </Bottom>
    </Div>
  );
};

export default inject(["store"])(observer(StorySnippet));
