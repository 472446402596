import React from "react";

const IconDownload = () => {
  return (
    <svg width="13" height="20" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path d="M0-1h24v24H0z" />
        <path
          d="M1.52 14.55v3.79h9.96v-3.8H13V20H0v-5.45h1.52zM6.84 0v11.58l3.1-3.14.24-.23.86.87.23.23-.23.23-4.75 4.78-.22.23-.23-.23-4.75-4.78-.22-.23.23-.23.86-.87.22.23 3.12 3.14V0h1.54z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default IconDownload;
