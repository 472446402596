import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import StorySnippet from "./StorySnippet";
import { mediaMedium } from "./styles/variables";
import { gray4 } from "./styles/Colors";
import { Headline64 } from "./styles/Headings";
import { inject, observer } from "mobx-react";

const Background = styled.div`
  background-color: ${(props) => props.backgroundColor ?? gray4};
  padding: 92px 0 96px;

  @media (${mediaMedium}) {
    padding: 152px 0 160px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 16px;
  margin-top: 80px;

  @media (${mediaMedium}) {
    margin-top: 89px;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  }
`;

const Title = styled(Headline64)`
  text-align: center;
`;

const HeroStories = ({ color, data, images, backgroundColor }) => {
  return (
    <Background backgroundColor={backgroundColor}>
      <Container>
        <Title theme={{ color: color ?? null }}>SUCCESS STORIES</Title>
        <Grid>
          {data.map((item, key) => (
            <StorySnippet
              key={"success-story-" + key}
              image={images["image" + key].childImageSharp.fluid}
              {...item}
            />
          ))}
        </Grid>
      </Container>

      {/*{ui.caseStudyLeadOpen !== "" && <CaseStudyLead />}*/}
    </Background>
  );
};

export default inject(["store"])(observer(HeroStories));
