import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroStories from "../HeroStories";
import { white } from "../styles/Colors";

const data = [
  {
    title: "Lemonade gains 5k+ leads in one year",
    topic: "consumer insurance",
    link: "/slides/StackMedia_Case_Study_Lemonade.pdf"
  },
  {
    title: "DiversyFund goes viral in Google Stories",
    topic: "consumer investing",
    link: "/slides/StackMedia_Case_Study_DiversyFund.pdf"
  },
  {
    title: "TikTok featured 20+ times in 10 days",
    topic: "consumer apps",
    link: "/slides/StackMedia_Case_Study_TikTok.pdf"
  }
];

const StackMediaStories = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "66-success-story-consumer-apps.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(
        relativePath: { eq: "66-success-story-consumer-investing.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(
        relativePath: { eq: "66-success-story-consumer-insurance.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <HeroStories backgroundColor={white} data={data} images={images} />;
};

export default StackMediaStories;
