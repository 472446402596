import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import Image from "./Image";
import { Headline40 } from "./styles/Headings";
import { Body20 } from "./styles/BodyTypes";
import { mediaMedium } from "./styles/variables";
import { black1, white } from "./styles/Colors";

const Wrapper = styled.div`
  background-color: ${black1};
`;

const Grid = styled(Container)`
  display: grid;

  @media (${mediaMedium}) {
    grid-template-columns: calc(100% * 6 / 12) calc(100% * 5 / 12);
    grid-gap: calc(100% * 1 / 12);
  }
`;

const Content = styled.div`
  padding-top: 45px;

  @media (${mediaMedium}) {
    order: 1;
    padding-top: 52px;
    padding-bottom: 60px;
  }
`;

const Display = styled.div`
  order: 3;
  margin: 0 0 -110px auto;
  transform: translateX(50px);

  @media (${mediaMedium}) {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    order: 1;
    margin: -105px 0 0 auto;
    transform: none;
  }
`;

const Background = styled.div`
  padding-top: 17px;
  overflow: hidden;

  &:nth-of-type(3) {
    ${Display} {
      transform: translateX(100px);
    }
  }

  @media (${mediaMedium}) {
    padding-top: 120px;

    &:nth-of-type(odd) {
      ${Display} {
        margin: -110px auto 0 0;
        order: 4;
      }
    }

    &:nth-of-type(2) {
      ${Display} {
        transform: none;
      }
    }
  }
`;

const Text = styled(Body20)`
  margin: 20px 0;
  color: ${white};

  &:last-child {
    margin-top: -3px;
  }

  @media (${mediaMedium}) {
    margin: 20px 0;
  }
`;

const Title = styled(Headline40)`
  margin: 32px 0 0;
  color: ${white};

  @media (${mediaMedium}) {
    margin: 32px 0;
  }
`;

const AudienceSnippet = ({ image, title, content, description, imageAltText }) => {
  return (
    <Background>
      <Wrapper>
        <Grid>
          <Display>
            <Image alt={imageAltText} {...image} />
          </Display>
          <Content>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Text>{description}</Text>
            <Text>{content}</Text>
          </Content>
        </Grid>
      </Wrapper>
    </Background>
  );
};

export default AudienceSnippet;
