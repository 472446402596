import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { black1 } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import MetricSnippet from "./MetricSnippet";
import BackgroundImage from "gatsby-background-image";

const Wrapper = styled(Container)`
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  text-align: center;
  grid-template-columns: 100%;
  display: grid;
  grid-row-gap: 60px;

  @media (${mediaMedium}) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 28px;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.backgroundColor ?? black1};
  height: auto;
  padding: 87px 16px;

  &:after,
  &:before {
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
    background-size: ${(props) =>
      props.noBackground ? "100% auto" : "140% auto"} !important;
  }

  @media (${mediaMedium}) {
    padding: 0;
    height: 560px;

    &:after,
    &:before {
      background-size: contain !important;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 280px;
  margin: 0 auto;
`;

const HeroMetricsThree = ({
  content,
  noBackground,
  backgroundDesktop,
  backgroundMedia,
  backgroundColor
}) => {
  let sources = null;

  if (backgroundDesktop && backgroundMedia) {
    sources = [
      backgroundMedia.childImageSharp.fluid,
      {
        ...backgroundDesktop.childImageSharp.fluid,
        media: `(${mediaMedium})`
      }
    ];
  }

  return (
    <Background
      as={noBackground ? "div" : BackgroundImage}
      fluid={sources}
      noBackground={noBackground}
      backgroundColor={backgroundColor}
    >
      <Wrapper>
        {content.map((item, key) => (
          <Item key={"ms" + key}>
            <MetricSnippet align={true} {...item} />
          </Item>
        ))}
      </Wrapper>
    </Background>
  );
};

export default HeroMetricsThree;
