import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Container from "../styles/Container";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import AudienceSnippet from "../AudienceSnippet";

const Wrapper = styled.div`
  padding-top: 96px;

  @media (${mediaMedium}) {
    padding-top: 144px;
  }
`;

const TitleRow = styled(Container)`
  text-align: center;
  margin-bottom: 16px;
`;

const data = [
  {
    title: "Chloe, <br/> The entrepreneur",
    description: "31  •  Lives in Vancouver  •  $200k income",
    imageAltText: "A short-haired woman smiling with her eyes closed, wearing a yellow dress, with her hand on her face.",
    content:
      "Motivated to grow her business’ revenue without hiring, be more successful without going over budget, please her investors, and be the first to know about the newest business tools and trends."
  },
  {
    title: "Adriana, <br/> The coder",
    description: "28  •  Lives in Austin  •  $150k income",
    imageAltText: "A long-haired woman with glasses, wearing a striped salmon sweater.",
    content:
      "Motivated to become a VP-level engineer with in-demand, high-paid skills, and to work to creatively to solve difficult problems."
  },
  {
    title: "Jake, <br/> The freelance designer",
    description: "26  •  Lives in Chicago  •  $65k income",
    imageAltText: "A man with glasses wearing a green shirt, sweater and a leather bag.",
    content:
      "Motivated by storytelling through graphics, landing new clients, getting consistent work."
  },
  {
    title: "Owen, <br/> The digital marketer",
    description: "35  •  Lives in Charlotte  •  $90k income",
    imageAltText: "A bearded man wearing a blue t-shirt.",
    content:
      "Motivated by being the first to know about the latest health and fitness finds, looking his best, growing his social media following, and living a “greener” life. "
  },
  {
    title: "Chris, <br/> The executive",
    description: "36  •  Lives in San Francisco  •  $180k income",
    imageAltText: "A bearded man wearing a gray business suit.",
    content:
      "Motivated by being a top performer, earning a bonus, completing products on-time and on-budget."
  }
];

const OurCoreAudiencesSection = ({ images }) => {
  return (
    <Wrapper>
      <TitleRow>
        <Headline80 as="h3">
          Our Core <br /> Audiences
        </Headline80>
      </TitleRow>
      {data.map((item, key) => (
        <AudienceSnippet
          key={"success-story-" + key}
          image={images["image" + key].childImageSharp.fluid}
          {...item}
        />
      ))}
    </Wrapper>
  );
};

const OurCoreAudiences = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "69-woman-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 362, maxHeight: 558, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "69-woman-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 468, maxHeight: 534, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "69-man-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 456, maxHeight: 523, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "69-man-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 329, maxHeight: 491, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "69-man-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 421, maxHeight: 489, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <OurCoreAudiencesSection images={images} />;
};

export default OurCoreAudiences;
